jQuery(function ($) {

    /**
     *
     * Nav fill
     *
     */

    $(window).scroll(function () {

        var scrollTop = $(window).scrollTop();

        if (scrollTop > 240) {
            $('.nav-desktop').addClass('nav-desktop--fill');
        }
        else {
            $('.nav-desktop--fill').removeClass('nav-desktop--fill');
        }

    });

    /**
     *
     * Hero background
     *
     */

    var heroBg = (function IIFE(){
        var ratio = 1920/972;

        return function(width,height){

            if( width < 1850 ){

                if( ratio <= width/height ){
                    $('.hero').css('background-size', '100% auto');
                    return false;
                }

                $('.hero').css('background-size', 'auto 100%');

            }

        };

    })();

    heroBg( $( window ).width(), $( window ).height() );

    /**
     *
     * Blog margin top
     *
     */

    var blogMargin = (function (){

        return function (margin) {
            $('.container-home').css('padding-top', margin );
        };

    })();

    blogMargin( ( $('.about').outerHeight() + 100 ) / 2 );


    /**
     *
     * Resize action
     *
     */

    $( window ).resize(function() {
        heroBg( $( window ).width(), $( window ).height() );
        blogMargin( ( $('.about').outerHeight() + 100 ) / 2 );
        sectionFacebook( $( window ).width() );
    });

    /**
     *
     * Nav toggler
     *
     */

    $('.nav-toggler').on('click', function(){

        $('.nav-toggle').slideToggle();

    });

    /**
     *
     * Section facebook
     *
     */

    var sectionFacebook = (function (){
        var ratioFacebook = 3238/600;

        return function (width) {
            $('.section-facebook').css('height', width/ratioFacebook );
        };

    })();

    sectionFacebook( $( window ).width() );


});
